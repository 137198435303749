<template>
  <div>

    <b-row v-if="role == 'superadmin' || role == 'admin'" class="fill-customer">
      <b-col md="4" sm="6" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">{{ $t("All customers") }}</label>
          <b-input-group size="sm" @click="getallcustomer">
            <b-form-select
              id="filterSelect"
              v-model="filterCustomerSelect"
              class="w-75"
              @change="filterCustomerSelectChanged"
            >
              <template>
                <option value="">-- {{ $t("Select customer") }} --</option>
                <option
                  :value="item.cusCode"
                  v-for="(item, idx) in allcustomer"
                  :key="idx"
                >
                  {{ item.cusName }}
                </option>
              </template>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-card>
      <b-row class="justify-content-center tab-report" v-if="role == 'superadmin' || role == 'admin' || cusData.empSummary == '1'"  style="border-bottom: 1px solid #dee2e6;"> 
        <b-button-group class="mb-1">
          <b-button
            variant="outline-primary"
            @click="$router.push('/report')"
          >
            <feather-icon icon="ClockIcon" class="mr-50" />
            <span class="font-weight-bold">{{ $t("Sort by date") }}</span>
          </b-button>
          <b-button
            variant="primary"
          >
            <feather-icon icon="UsersIcon" class="mr-50" />
            <span class="font-weight-bold">{{ $t("Sort by Employeer") }}</span>
          </b-button>
        </b-button-group>
      </b-row>

      <b-row>
        <b-col md="3" sm="6" class="my-1 pl-0 pr-0">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50"
              >{{ $t("Month") }}</label
            >
            <b-input-group size="sm">
              <b-form-select
                id="filter_month"
                class="w-75"
                v-model="f_monthVal"
                @change="getbigdata"
              >
                <template>
                  <option
                    :value="item.value"
                    v-for="(item, idx) in fs_month"
                    :key="idx"
                  >
                    {{ item.name }}
                  </option>
                </template>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="5" class="d-sm-none d-md-block"></b-col>
        <b-col md="4" sm="6" class="my-1 pl-0 pr-0">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50"
              >{{ $t("Find employeer") }}</label
            >
            <b-input-group size="sm">
              <!-- <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="ชื่อพนักงาน"
                @keyup="searchEnter()"
                @keydown="searchEnter()"
                @change="searchEnter()"
              /> -->
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                :placeholder="$t('Name')"
                @change="searchEnter()"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="searchClear()"
                  style="border-radius: 0 0.357rem 0.357rem 0"
                >
                {{ $t("Clear") }}
                </b-button>
              </b-input-group-append>

              <!-- <b-button
                style="margin-left: 5px; border-radius: 0.357rem"
                variant="primary"
                @click="showAllData"
                size="sm"
              >
                <span class="text-nowrap">ดูรายงานทั้งหมด</span>
              </b-button> -->
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col md="12" class="mb-1 fill-section">
          <b-form-group class="mb-0">
            <span
              class="text-nowrap text-dark font-weight-bold"
              style="line-height: 2"
            >
            {{ $t("Show") }} : </span
            >
            <b-dropdown id="dropdown-1" :text="filtxt_show_type" size="sm" variant="primary">
              <b-dropdown-item @click="changedfilltype('all')">{{ $t("All") }}</b-dropdown-item>
              <b-dropdown-item @click="changedfilltype('active')">{{ $t("Show emp test") }}</b-dropdown-item>
              <b-dropdown-item @click="changedfilltype('none')">{{ $t("Show emp no") }}</b-dropdown-item>
            </b-dropdown>
            <span
              class="text-nowrap text-dark font-weight-bold ml-1 mr-1"
              style="line-height: 2"
            >
              {{ $t("Emp total") }}: {{ totalRows }} {{ $t("People") }}</span
            >
            <b-button
              size="sm"
              variant="success"
              class="mr-25"
              @click="exportExcelFile()"
              style="background-color: #157347 !important"
            >
              <feather-icon icon="FileTextIcon" class="mr-25" />
              <span class="text-nowrap">{{ $t("Download") }} </span>
              <b-spinner v-if="exportProcess" small></b-spinner>
            </b-button>

            <!-- <b-button
              size="sm"
              variant="primary"
              class="mr-25"
              @click="printFile()"
            >
              <feather-icon icon="PrinterIcon" class="mr-25" />
              <span class="text-nowrap">พิมพ์ </span>
            </b-button> -->
          </b-form-group>
        </b-col>


        <!-- <b-col md="2" sm="4" class="my-1 pl-0">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Show</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              @change="perPageChanged()"
              class="w-50"
            />
            <label class="text-sm-left ml-50">Name</label>
          </b-form-group>
        </b-col> -->
        
        <table id="table-member-sum" class="table table-responsive">
          <thead>
            <tr>
              <th scope="col" rowspan="2" class="text-center">{{ $t("No") }}.</th>
              <th scope="col" rowspan="2" class="col-name">{{ $t("Name") }}</th>
              <th scope="col" rowspan="2" class="col-id_code">{{ $t("ID CODE") }}</th>
              <th scope="col" rowspan="2" class="col-type">{{ $t("Type") }}</th>
              <th scope="col" rowspan="2" class="text-center">{{ $t("Position") }}</th>
              <th scope="col" rowspan="2" class="text-center col-250">{{ $t("TEST") }}</th>
              <th v-for="(item, index) in head_day" :key="index" scope="col" class="text-center col-date-num" :class= "[item.date_name == 'Su' ? 'th-end-week' : '']">{{ item.date_no }}</th>
            </tr>
            <tr>
              <th v-for="(item, index) in head_day" :key="index" scope="col" class="text-center" :class= "[item.date_name == 'Su' ? 'th-end-week' : '']">{{ item.date_name }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, index) in bigdata">
              <tr v-if="chkrow(index) != ''" :key="index" class="bd-new-line">
                <td :rowspan="rs_mem" class="text-center">{{ chkrow(index) }}</td>
                <td :rowspan="rs_mem">
                  <b-link
                    :to="{ name: 'chart-report', params: { id: item.m_uid } }"
                  >
                    {{ item.firstname }} {{ item.lastname }}
                  </b-link>
                </td>
                <td :rowspan="rs_mem">{{ item.emp_id }}</td>
                <td :rowspan="rs_mem">{{ item.position }}</td>
                <td :rowspan="rs_mem" class="text-center">{{ item.dep_name }}</td>
                
                <template v-if="item.name == 'chkin_time'">
                  <td class="checkin-bg text-center text-bold" >
                    <span>{{ $t("Time Test before") }}</span>
                  </td>
                  <td v-for="(date_val, key_date) in item.date_month" :key="key_date" class="checkin-bg text-right">
                      <span>{{date_val}}</span>
                  </td>
                </template>
                
              </tr>
              <tr v-else :key="index">
                <td v-if="item.name == 'chkout_time'" class="checkin-bg text-center text-bold">
                  <span>{{ $t("Time Test after") }}</span>
                </td>
                <td v-else-if="item.name == 'time_relax'" class="checkin-bg text-center text-bold">
                  <span style="font-size: 13px;">{{ $t("Time Relax") }} </span>
                </td>
                <td v-else class="text-test text-left">
                  <span v-if="item.name == 'alcohol'">{{ $t("ALCOHOL") }}  (0 mg%)</span>
                  <span v-else-if="item.name == 'sys'">{{ $t("SYS") }}  (90-140 mmHg)</span>
                  <span v-else-if="item.name == 'dia'">{{ $t("DIA") }}  (60-90 mmHg)</span>
                  <span v-else-if="item.name == 'temp'">{{ $t("Temp") }}  (<37.5°C)</span>
                  <span v-else-if="item.name == 'heart_rate'">{{ $t("Heart Rate") }} </span>
                  <span v-else-if="item.name == 'al_out'">{{ $t("ALCOHOL") }}  (0 mg%)</span>
                  <span v-else-if="item.name == 'tp_out'">{{ $t("Temp") }} </span>
                  <span v-else>{{item.name}}</span>
                </td>
                
                <template v-for="(date_val, key_date) in item.date_month">
                  <td v-if="item.name == 'chkout_time'" :key="key_date"  class="checkin-bg text-center">
                    <span>{{date_val}}</span>
                  </td>
                  <td v-else-if="item.name == 'time_relax'" :key="key_date"  class="checkin-bg text-center">
                    <!-- <span v-if="date_val.length > 10"></span>
                    <span v-else>{{date_val}}</span> -->
                    <span class="text-bold" :class= "[chkTimeRelax(item.date_month[key_date+1]) ? 'text-red' : '']">
                      {{item.date_month[key_date+1]}}
                    </span>
                  </td>
                  <td v-else :key="key_date"  class="text-center">
                    <span v-if="item.name == 'alcohol'">
                      <span v-if="date_val != '' || date_val == '0'" :class= "[date_val > 0 ? 'text-red' : '']">{{date_val}}</span>
                      <span v-else></span>
                    </span>
                    <span v-else-if="item.name == 'sys'">
                      <span v-if="date_val > 0" :class= "[date_val < 90 || date_val > 140 ? 'text-red' : '']">{{date_val}}</span>
                      <span v-else></span>
                    </span>
                    <span v-else-if="item.name == 'dia'">
                      <span v-if="date_val > 0" :class= "[date_val < 60 || date_val > 90 ? 'text-red' : '']">{{date_val}}</span>
                      <span v-else></span>
                    </span>
                    <span v-else-if="item.name == 'temp'">
                      <span v-if="date_val > 0" :class= "[date_val > 37.5 ? 'text-red' : '']">{{date_val}}</span>
                      <span v-else></span>
                    </span>
                    <span v-else-if="item.name == 'heart_rate'">
                        <span v-if="date_val > 0">{{date_val}}</span>
                        <span v-else></span>
                    </span>
                    <span v-else-if="item.name == 'al_out'">
                      <span v-if="date_val != '' || date_val == '0'" :class= "[date_val > 0 ? 'text-red' : '']">{{date_val}}</span>
                      <span v-else></span>
                    </span>
                    <span v-else>
                        <span>{{date_val}}</span>
                    </span>
                  </td>
                </template>
              </tr>
            </template>
          </tbody>
        </table>

        <b-col md="12" class="mb-1">
          <span
              class="text-nowrap text-red font-weight-bold mr-1"
              style="line-height: 2"
            >
              <i>{{ $t("Note Time Relax") }}</i></span
            >
            <br>
          <span
              class="text-nowrap text-dark font-weight-bold mr-1"
              style="line-height: 2"
            >
              {{ $t("Showing") }} : {{ totalRows }} {{ $t("Of") }} {{ emp_total}} {{ $t("People") }}</span
            >
        </b-col>
        <b-col md="4" sm="12" class="mt-1">
        </b-col>
        <b-col md="4" sm="12" class="mt-1">
          <b-button block variant="primary" v-if="totalRows < emp_total" @click="loadmoreData()" :disabled="(loadMoreProcess ? true:false)"> <span v-if="!loadMoreProcess">Load More</span>
            <b-spinner v-if="loadMoreProcess" label="Spinning" style="width: 1.5rem; height: 1.5rem;"></b-spinner>
          </b-button>
        </b-col>
        <b-col md="4" sm="12" class="mt-1">
        </b-col>
        
      </b-row>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BCard,
  BCardHeader,
  BCardText,
  BLink,
  BTable,
  BFormCheckbox,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BButtonGroup,
  BImg,
  BDropdown,
  BDropdownItem,
  BTabs, BTab,
  BSpinner 
} from "bootstrap-vue";
import jwt_decode from "jwt-decode";
import reportService from "@/services/reportService.js";
import customerService from "@/services/customerService.js";
import productService from "@/services/productService.js";
import moment from "moment";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardText,
    BLink,
    BTable,
    BFormCheckbox,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BButtonGroup,
    BImg,
    BDropdown,
    BDropdownItem,
    BTabs, BTab,
    BSpinner,
  },
  data() {
   
    return {
      cusCode: null,
      role: "",
      filterCustomerSelect: "",
      filtxt_show_type: this.$t("All"),
      q_show_type: "",
      perPage: 100,
      pageOptions: [20, 30, 50, 100],
      totalRows: 1,
      currentPage: 1,
      currentPagetable: 1,
      sortBy: "",
      sortDesc: false,
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      filterAll: "",
      filterProset: "",
      fs_month: [],
      f_monthVal: moment().endOf('month').format('YYYY-MM-DD'),
      filterSelect_q: "",
      filterSearch: "",
      filterDate: "",
      filterperPage: "&per_page=100",
      filtersortBy: "",
      filtersortDesc: "",
      head_day: [],
      rs_mem: 10,
      bigdata: [],
      allcustomer: [],
      emp_total: 0,
      loadMoreProcess: false,
      exportProcess: false,
      modelOption: [],
      cusData: {},
    };
  },
  computed: {
  },
  mounted() {},
  methods: {
    chkTimeRelax(val) {
      if (val) {
        let i = val.indexOf(":");
        if (i > 0) {
          let arr = val.split(":")
          if (arr[0] <= 7) {
            return true;
          }
        }
      }
    },
    chkrow(val) {
      let res = ''
      if (val == 0) {
        res = val+1
      } else {
        if ( (val % this.rs_mem) == 0 ) {
          res = (val/this.rs_mem)+1
        }
      }

      return res
    },
    filterCustomerSelectChanged() {
      this.cusCode = this.filterCustomerSelect;
      if (!this.filterCustomerSelect) {
        this.cusCode = null;
      }

      this.getbigdata();
    },
    f_date_q() {
      let cut_ym = this.f_monthVal.substr(0, 8);
      this.filterDate = "&startDate="+cut_ym+"01&endDate="+this.f_monthVal;
    },
    searchEnter() {
      if (this.filter) {
        this.filterSearch = "&search=" + this.filter;
      } else {
        this.filterSearch = "";
      }

      if (this.filter.length == 0 || this.filter.length > 2) {
        this.getbigdata();
      }
    },
    searchClear() {
      this.filter = "";
      this.filterSearch = "";
      this.getbigdata();
    },
    changedfilltype(val) {
      if (val == 'active') {
        this.q_show_type = "&q_show_type=active";
        this.filtxt_show_type = this.$t("Show emp test")
      } else if (val == 'none') {
        this.q_show_type = "&q_show_type=none";
        this.filtxt_show_type = this.$t("Show emp no")
      } else {
        this.q_show_type = "";
        this.filtxt_show_type = this.$t("All")
      }
      this.getbigdata();
    },
    perPageChanged() {
      this.filterperPage = "&per_page=" + this.perPage;
      this.getbigdata();
    },
    sortingChanged(e) {
      if (e.sortBy) {
        this.filtersortBy = "&sortBy=" + e.sortBy;
      }

      if (e.sortDesc) {
        this.filtersortDesc = "&sortDesc=DESC";
      } else {
        this.filtersortDesc = "&sortDesc=ASC";
      }
      this.getbigdata();
    },
    handlePageChange() {
      this.getbigdata();
    },
    showAllData() {
      // this.filterSelect = "";
      // this.filter = "";
      // this.filterSearch = "";
      // this.filterSelect_q = "";
      // this.currentPage = 1;

      // let today = new Date();
      // let startDate = new Date("2022-01-01");
      // let endDate = new Date(
      //   today.getFullYear(),
      //   today.getMonth(),
      //   today.getDate(),
      //   11,
      //   59,
      //   59,
      //   999
      // );
     
      // this.getbigdata();
    },
    getbigdata(type = '') {
      if (type != 'loadmore') {
        this.currentPage = 1
      }
      this.f_date_q();
      var page = "page=" + this.currentPage;
      this.filterAll =
        "?" +
        page +
        "&cuscode="+ this.cusCode +
        this.filterSearch +
        this.filterSelect_q +
        this.filterDate +
        this.filterperPage +
        this.filtersortBy +
        this.filtersortDesc +
        this.q_show_type;

      try {
        reportService
          .getMemberSumCheckin(this.filterAll)
          .then((response) => {
            //console.log(response.data.data)

            if (type == 'loadmore') {
              //this.bigdata = Object.assign({}, this.bigdata, response.data.data);
              response.data.data.forEach((item) => {
                this.bigdata.push(item)
              })
              this.totalRows += (response.data.total/this.rs_mem)
              this.loadMoreProcess = false
            } else {
              this.bigdata = response.data.data;
              this.head_day = response.data.head_data
              this.totalRows = response.data.total/this.rs_mem
              this.currentPage =1;
              this.emp_total = response.data.emp_total
            }
          })
          .catch(() => {});
      } catch (e) {
        throw e;
      }
    },
    loadmoreData() {
      this.currentPage +=1;
      this.loadMoreProcess = true
      this.getbigdata('loadmore')
    },
    exportExcelFile() {
      this.exportProcess = true

      this.f_date_q();
      var page = "page=" + this.currentPage;
      this.filterAll =
        "?" +
        page +
        "&cuscode="+ this.cusCode +
        this.filterSearch +
        this.filterDate;

      try {
        reportService
          .exportMemberChkinSum(this.filterAll)
          .then((response) => {
              this.exportProcess = false
              const link = document.createElement("a");
              link.href = process.env.VUE_APP_UPLOAD_IMAGE+'/export/'+response.data.filename;
              link.click();
          })
          .catch(() => {});
      } catch (e) {
        throw e;
      }

    },
    getallcustomer() {

      if (this.allcustomer.length == 0) {
        var page = "page=" + this.currentPage;
        this.filterAll =
          "?" +
          page +
          this.filterSearch +
          this.filterSelect_q +
          this.filterDate +
          "&per_page=50" +
          this.filtersortBy +
          this.filtersortDesc;

        try {
          customerService
            .getalldata(this.filterAll)
            .then((response) => {
              this.allcustomer = response.data.data.data;
              //console.log(this.getalldata);
            })
            .catch(() => {});

          // this.data1 = response.data;
          // console.log(this.User_data);
        } catch (e) {
          // console.log(e);
        }
      }
    },
    getcusByCode() {
      try {
        customerService
          .getdataBycusCode(this.cusCode)
          .then((response) => {
            this.cusData = response.data.data

            this.getproductOpt();
          })
          .catch(() => {});

        // this.data1 = response.data;
        // console.log(this.User_data);
      } catch (e) {
        // console.log(e);
      }
    },
    getproductOpt() {
      try {
        productService
          .getProSetOptByCus(this.cusCode)
          .then((response) => {
            this.modelOption = response.data.data
          })
          .catch(() => {});

      } catch (e) {
        // console.log(e);
      }
    },
    showtoken() {
      var token = localStorage.getItem("token");
      var decoded = jwt_decode(token);
      this.role = decoded.role;

      if (decoded.role == "superadmin" || decoded.role == "admin") {
        this.cusCode = null;
      } else {
        this.cusCode = decoded.cusCode;
      }
    },
    getMouthFilter() {
      this.fs_month.push({name: moment().format('MMMM YYYY'), value: moment().endOf('month').format('YYYY-MM-DD')})
      for (let i = 1; i < 3; i++) {
        this.fs_month.push({name: moment().subtract(i, 'months').format('MMMM YYYY'), value: moment().subtract(i, 'months').endOf('month').format('YYYY-MM-DD')})
      }
    }
  },
  async created() {
    await this.showtoken();
    await this.getbigdata();
    if (this.role != "superadmin" && this.role != "admin") {
      await this.getcusByCode();
    }
    this.getMouthFilter();
  },
};
</script>

<style lang="scss">
.export-menu {
  text-align: right;
  text-align: right;
  margin-bottom: -35px;
  font-size: 25px;
}
.reportrange-text {
  padding: 8px 35px !important;
}

.feather.feather-calendar {
  position: absolute;
  margin-top: 25px;
  z-index: 1;
  margin-left: -25px;
}

.log-emp-photo {
  transition: all 0.5s ease-in-out;
}
.log-emp-photo:hover {
  transform: translateY(-4px) scale(6);
  z-index: 10;
}

#table-member-sum { 
  th, td {
    vertical-align: middle !important;
  }
  tr th {
    background-color: #f9d9c2 !important;
    color: black;
    font-weight: bold;
    border: solid 1px #9E9E9E;
    padding: 2px 2px !important;
    text-transform: unset !important;
  }

  tr.bd-new-line {
    border-top: solid 2px #000;
  }

  td {
    padding: 2px 2px !important;
    font-size: 12px;
    border: solid 1px #9E9E9E;
    color: black;
  }

  th.th-end-week {
    background-color: #ffff7d !important;
  }

  .col-name {
    width: 100%;
    min-width: 70px !important;
    word-wrap: break-word;   
  }

  .f_name, .l_name {
    color: unset !important;
    cursor: pointer !important;
  }

  .col-id_code {
    min-width: 80px !important;
  }

  .col-type {
    min-width: 50px !important;
  }

  .col-250 {
    min-width: 180px;
  }

  .col-85 {
    min-width: 85px;
  }

  .text-test {
    background-color: #fff7f4 !important;
  }

  .col-date-num {
    min-width: 30px;
  }

  .checkin-bg {
    background-color: #fce4d7 !important;
  }

}

.text-red {
  color: red;
}
.text-bold {
  font-weight: bold;
}
.col-detail {
  width: 30px;
  padding: 5px !important;
}
.col-date_at {
  min-width: 100px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.col-like {
  min-width: 150px !important;
}

// .vue-daterange-picker {
//   ::after {
//     padding-left: 20px !important;
//   }
//   ::before {
//     padding-left: 20px !important;
//   }
// }


@media print {
  @page :left {
    margin-left: 0.5cm;
  }

  @page :right {
    margin-left: 0.5cm;
  }

  @page {
    size: landscape;
  }
  
  body {
    background-color: transparent !important;
    padding: 0rem !important;
    margin: 0 !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    padding: 0rem !important;
    margin: 0 !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .fill-customer, .tab-report, .fill-section {
    display: none !important;
  } 
  #table-member-sum {
    width: 100%;
    min-width: 2500px;
    flex-grow: 1;
    
    .table thead th, .table tfoot th, #table-member-sum td {
      font-size: 10px !important;
    }
    .col-250 {
      min-width: 180px !important;
      width: unset !important;
    }
    .col-name {
      width: 120px !important;
      min-width: 50px !important;
    }
    .col-type {
      width: 50px !important;
      min-width: 50px !important;
    }
  }
}

</style>
